import React, { useState } from "react";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import Logo from '../logo.png'
import BG from '../assets/images/background.jpg';
import { Link } from "react-router-dom";
function Sidebar(props){
    const [pcToggle,setPcToggle] = useState(false);
    const [mobileToggle,setmobileToggle] = useState(props.mobiletoggle);
    return (<>
    <ProSidebar collapsed={pcToggle} image={BG} toggled={props.mobiletoggle} breakPoint={'xl'}>
    <div className="pctoggle">
    <img className="w-50" src={Logo}/>
    <i className="fa fa-bars pc-icon" onClick={pcToggle == true ?(e)=>setPcToggle(false):(e)=>setPcToggle(true)}></i>
    <i className="fa fa-times mobile-icon" onClick={(e)=>props.mobile(false)}></i>
    </div>
  <Menu iconShape="square">
    {/* <MenuItem icon={'icon'}>Dashboard</MenuItem> */}
    <MenuItem icon={<i class="fas fa-project-diagram"></i>}><Link to={'/oracles'}>Oracles</Link></MenuItem>
    <SubMenu title="IDOs" icon={<i class="fas fa-project-diagram"></i>}>
      <MenuItem>
      <Link to={"idos/live"} onClick={(e)=>props.mobile(false)}>
      Live Sales
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"idos/upcoming"} onClick={(e)=>props.mobile(false)}>
      Upcoming Sales
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"idos/finished"} onClick={(e)=>props.mobile(false)}>
      Past Sales
        </Link>
        </MenuItem>
    </SubMenu>
    <SubMenu title="HCI Projects" icon={<i class="fas fa-project-diagram"></i>}>
      <MenuItem>
      <Link to={"hci/live"} onClick={(e)=>props.mobile(false)}>
      Live Shares
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"hci/upcoming"} onClick={(e)=>props.mobile(false)}>
      Upcoming Shares
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"hci/finished"} onClick={(e)=>props.mobile(false)}>
      Past Shares
        </Link>
       
        </MenuItem>
    </SubMenu>
    <SubMenu title="MarketPlace" icon={<i class="fa fa-signal"></i>}>
      <MenuItem>
      <Link to={"p2p-swaps"} onClick={(e)=>props.mobile(false)}>
      P2P Swaps
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"b2b-market"} onClick={(e)=>props.mobile(false)}>
      B2B Market
        </Link>
      </MenuItem>
      <MenuItem>
      <Link to={"nft-market"} onClick={(e)=>props.mobile(false)}>
      NFT Market
        </Link>
        </MenuItem>
 
    </SubMenu>
    <MenuItem icon={<i class="fas fa-home"></i>}><Link to={'/dashboard'}>Staking</Link></MenuItem>
    <MenuItem icon={<i class="fa fa-tasks"></i>}><Link to={'/allocations'}>Allocations</Link></MenuItem>
    <SubMenu title="Tokens" icon={<i class="fa fa-bitcoin"></i>}>
      <MenuItem>
      <Link to={"tokens"} onClick={(e)=>props.mobile(false)}>
      Deployed Tokens
        </Link>
      </MenuItem>
    </SubMenu>
    
  </Menu>
</ProSidebar>

  
   
    </>
    )
}
export default Sidebar;