function TopBar(props){
    return <>
    <div className="top-bar">
        <i className="fa fa-bars" onClick={(e)=>props.mobile(true)}></i>
                    <div className="flex">
                    <div >
                    <p>HORIZONPAD v1</p>
                    </div>
                    <div>
                    <button class="text-uppercase rounded-pill connect-btn  my-3">
					CONNECT
				</button>
                    </div>
                    </div>
            </div>
    </>
}
export default TopBar;