import React, { useState } from "react";
import { Col, Container, Dropdown, Nav, ProgressBar, Row, Table } from "react-bootstrap";
import { FaUtensilSpoon } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Pancakeswap from '../assets/images/pancakeswap.png';
import Uniswap from '../assets/images/uniswap.png';
import Coinbase from '../assets/images/coinbase.png';
import Binance from '../assets/images/binance.svg';
function Dashboard(props){
    const [stakevalue,setStakevalue] = useState('');
    const [staketype,setStaketype] = useState('stake');
    return (
        <>
                <h3 className="main-heading">DASHBOARD</h3>
                <br/>
                <Row>
                    <Col lg={8} sm={12} md={6} >
                   
                        <div className="content-section">
                            <div className="text-box">
                            <p>TOTAL POLS POWER (UPDATES EVERY DAY)</p>
                            <h2>0</h2>
                            </div>
                            <div className="text-box">
                            <p>TOTAL POLS STAKED ON CURRENT NETWORK (REAL TIME)</p>
                            <h2>0</h2>
                            </div>
                            <div className="text-box">

                            <div class="btn-group d-flex">
                                <button class="btn-custom btn-white-border">Buy on Uniswap</button>
                                <Dropdown>
                                <Dropdown.Toggle className="btn-custom btn-white-border" id="dropdown-button-dark-example1" >
                                Buy POLS
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant="dark">
                                <Dropdown.Item href="#/action-2"><span>Pancakeswap</span> <img src={Pancakeswap}/></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><span>Uniswap</span> <img src={Uniswap}/></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><span>Binance</span> <img src={Binance}/></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><span>Coinbase</span> <img src={Coinbase}/></Dropdown.Item>
                                </Dropdown.Menu>
                             </Dropdown>
                                
                            </div>
                            </div>
                           
                        </div>
                        
                    </Col>
                    <Col lg={4} sm={12} md={6}>
                        <div className="box form-small">
                        <div className="box-header">
                            <Nav variant="pills" defaultActiveKey="link-1">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1" onClick={(e)=>setStaketype('stake')}>Stake</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2" onClick={(e)=>setStaketype('unstake')}>Unstake</Nav.Link>
                        </Nav.Item>
                        </Nav>
                            </div>
                           {
                               staketype == 'stake' ? <>
                            <div className="form">
                           <p>Stake POLS to participate in whitelists for upcoming IDOs.</p>
                           <div className="custom-form-group">
                               <div className="d-j-flex">
                                   <span>Ammount</span>
                                   <span>0 POLS</span>
                               </div>
                               <div className="relative">
                               <input className="form-control" value={stakevalue} onChange={(e)=>setStakevalue(e.target.value)}/>
                                <div className="absolute-group">
                                <button class="btn-custom btn-white-border" onClick={(e)=>setStakevalue(0)}>Max</button>
                                <i class="fas fa-poll"></i>
                                </div>
                               </div>
                            </div>                           
                            <div className="btn-group">
                            <button class="btn-custom btn-white-border"><i class="fas fa-lock"></i><span>Stake and Lock</span></button>
                            </div>
                            <div className="footer mt-3">
                                <p>Your POLS tokens will be locked for 7 days. After that time, you’re free to withdraw at any time.</p>
                            </div>
                           </div>
                               </>:
                               <>
                                 <div className="form">
                           <p>When you unstake your POLS, you lose access to all future IDO whitelists.</p>
                           <div className="custom-form-group">
                               <div className="d-j-flex">
                                   <span>Amount to Unstake</span>
                                   <span>0 POLS</span>
                               </div>
                               <div className="relative">
                               <input className="form-control" value={stakevalue} onChange={(e)=>setStakevalue(e.target.value)}/>
                                <div className="absolute-group">
                                <button class="btn-custom btn-white-border" onClick={(e)=>setStakevalue(0)}>Max</button>
                                <i class="fas fa-poll"></i>
                                </div>
                               </div>
                            </div>                           
                            <div className="btn-group">
                            <button class="btn-custom btn-white-border">Unstake</button>
                            </div>
                            <div className="footer mt-3">
                            </div>
                           </div>
                               </>
                           }
                        </div>
                    </Col>
                    <Col lg={8} sm={12} md={6} >
                    <h2 className="text-black mt-5">Your Applications</h2>
                    <Table striped bordered hover responsive >
                        
                                <thead>
                                <tr>
                                <th class=""> Project Name</th>
                                <th class="">IDO Date</th>
                                <th class="">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <div className="tbody-text-section">
                                <h3 className="text-black">Start by applying to an upcoming project</h3>
                                <p>To get whitelisted you first need to apply to a Project. <Link to='/' className="anchor">Learn how to apply here.</Link></p>
                                <Link to='/auction/live' class="btn-custom btn-white-border">Go to Projects</Link>
                                </div>
                                </tbody>
                                </Table>
                        
                    </Col>
                    <Col lg={4} sm={12} md={6} >
                        <div className="box form-small mt-5">
                            <h2 className="text-black">POLS tiers</h2>
                            <div className="form">
                            <ul>
                                <li><span>250+ POLS</span> <span>5.18%</span></li>
                                <li><span>250+ POLS</span> <span>5.18%</span></li>
                                <li><span>250+ POLS</span> <span>5.18%</span></li>

                            </ul>
                            <div className="w-100 mt-3">
                            <button class="btn-custom btn-white-border m-auto"><span>Buy POLS and Level Up</span></button>
                            </div>
                            <div className="footer mt-3">
                                <p>Probabilities are averages calculated over the most recent IDOs. Chances are not guaranteed.</p>
                            </div>
                           </div>
                             
                        </div>
                    </Col>
                </Row>
        
        </>
    )
}
export default Dashboard;