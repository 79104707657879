import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaUtensilSpoon } from 'react-icons/fa';
import { Link } from "react-router-dom";
import B2BImg from '../../assets/images/b2b.jpg';
function B2B(props){

    return (
        <>
                <h3 className="main-heading">B2B MARKET</h3>
                <Row>
                    <Col lg={12} sm={12} md={12}>
                        <h2 className="main-heading comming-soon">Comming Soon</h2>
                        <div className="comming-soon-img">

                        <img src={B2BImg} width={'100%'} />
                        </div>
                    </Col>
                 
                   
                   
                </Row>
        
        </>
    )
}
export default B2B;