import React, { useState } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import { Button } from 'react-bootstrap';
import Logo from '../../logo.png';

function Deploy() {
  const [datatable, setDatatable] = useState({
    columns: [
        {
            'label': '',
            'field': 'img',
            'sort': 'asc'
          },
      {
        label: 'Name',
        field: 'name',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Symbol',
        field: 'symbol',
        width: 270,
      },
      {
        label: 'Address',
        field: 'address',
        width: 200,
      },
      {
        label: 'Token Info',
        field: 'info',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: [
     
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
      {
        img: <img width={50} src={Logo}/>,
        name: 'GenomesDAO',
        symbol: 'GENE',
        address: '0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E ',
        info: <a href='https://rinkeby.etherscan.io/token/0x21413c119b0C11C5sdasdf1bD328917bC5C8ED67E' target='_blank' className='btn btn-skyblue'>View Info</a>,
      },
    
    ],
  });

  return (
    <>
    <h3 className="main-heading">Deployed Token</h3>
    <MDBDataTableV5
      hover
      entriesOptions={[5, 20, 25]}
      entries={5}
      pagesAmount={4}
      data={datatable}
      pagingTop
      searchTop
      searchBottom={false}
    />
    </>
  );
}
export default Deploy;