import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaUtensilSpoon } from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from "react-router-dom";
import CrowdSale from '../../assets/images/crowdsale.png';

function Oracle(props){
    return (
        <>
                <h3 className="main-heading">ORACLE PROJECTS</h3>
                <Row>
                    <Col lg={4} sm={12} md={6}>
                        <div className="box">
                            <div className="b-btn-group">
                            <div>
                                <img width={100} src="https://storage.googleapis.com/polkastarter-production-assets/g1lh0pka1buydth3h0k38nk2t51b"/>
                            </div>
                            <div>
                           
                            </div>
                            </div>
                            <h4 className="title">DotOracle (DTO)</h4>
                            <h5 className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
                            <hr/>
                            <div className="calc-box">
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Project Web</a>
                                </div>
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Cmc Link</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} md={6}>
                        <div className="box">
                            <div className="b-btn-group">
                            <div>
                                <img width={100} src="https://storage.googleapis.com/polkastarter-production-assets/g1lh0pka1buydth3h0k38nk2t51b"/>
                            </div>
                            <div>
                           
                            </div>
                            </div>
                            <h4 className="title">DotOracle (DTO)</h4>
                            <h5 className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
                            <hr/>
                            <div className="calc-box">
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Project Web</a>
                                </div>
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Cmc Link</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} md={6}>
                        <div className="box">
                            <div className="b-btn-group">
                            <div>
                                <img width={100} src="https://storage.googleapis.com/polkastarter-production-assets/g1lh0pka1buydth3h0k38nk2t51b"/>
                            </div>
                            <div>
                           
                            </div>
                            </div>
                            <h4 className="title">DotOracle (DTO)</h4>
                            <h5 className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
                            <hr/>
                            <div className="calc-box">
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Project Web</a>
                                </div>
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Cmc Link</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} md={6}>
                        <div className="box">
                            <div className="b-btn-group">
                            <div>
                                <img width={100} src="https://storage.googleapis.com/polkastarter-production-assets/g1lh0pka1buydth3h0k38nk2t51b"/>
                            </div>
                            <div>
                           
                            </div>
                            </div>
                            <h4 className="title">DotOracle (DTO)</h4>
                            <h5 className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
                            <hr/>
                            <div className="calc-box">
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Project Web</a>
                                </div>
                                <div className="anchor">
                                <a href="www.test.com" target="_blank" >Cmc Link</a>
                                </div>

                            </div>
                        </div>
                    </Col>
                   
                </Row>
        
        </>
    )
}
export default Oracle;