import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Live from './screens/market/Live';
import PastSale from './screens/market/PastSale';
import UpcomingSale from './screens/market/UpcomingSale';
import Detail from './screens/market/Detail';
import B2B from './screens/market/B2B';
import P2P from './screens/market/P2P';
import NFTMarket from './screens/market/NFT';
import Dashboard from './screens/Dashboard';
import Allocation from './screens/Allocation';
import Deploy from './screens/tokens/Deploy';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Oracle from './screens/market/Oracle';



import { useEagerConnect, useInactiveListener } from './hooks/useEagerConnect';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import IdoUpcomingSale from './screens/market/idos/UpcomingSale';
import IdoLive from './screens/market/idos/Live';
import IdoPastSale from './screens/market/idos/PastSale';


function App() {
  const [errorMessage, setErrorMessage] = useState();
  useEagerConnect(setErrorMessage);
  useInactiveListener();
  const [mobileToggle,setmobileToggle] = useState(false);

  return (
    <>
    <div className="app">
     <Router>
    <Sidebar mobiletoggle={mobileToggle} mobile={setmobileToggle}/>
     <div className="main">
       <TopBar mobile={setmobileToggle}/>
     <div className="main-padding">
        <Routes>
        <Route path="/" element={ <Live />}  />
        <Route path="/hci/live" element={ <Live />}  />
        <Route path="/hci/finished" element={ <PastSale />}  />
        <Route path="/hci/upcoming" element={ <UpcomingSale />}  />
        <Route path="/idos/live" element={ <IdoLive />}  />
        <Route path="/idos/finished" element={ <IdoPastSale />}  />
        <Route path="/idos/upcoming" element={ <IdoUpcomingSale />}  />
        <Route path="/oracles" element={ <Oracle />}  />

        <Route path="/tokens" element={ <Deploy />}  />
        <Route path="/b2b-market" element={ <B2B />}  />
        <Route path="/p2p-swaps" element={ <P2P />}  />
        <Route path="/nft-market" element={ <NFTMarket />}  />
        <Route path="/detail" element={ <Detail />}  />
        <Route path="/dashboard" element={ <Dashboard />}  />
        <Route path="/allocations" element={ <Allocation />}  />
        </Routes>
      </div>
      </div>
    </Router>
      {
        errorMessage? <div style={{color:"red"}}>{errorMessage}</div>: null
      }
       
       </div>
    </>
  );
}

export default App;
