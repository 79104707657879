import React, { useState } from "react";
import { Col, Container, Nav, ProgressBar, Row, Table } from "react-bootstrap";
import { FaUtensilSpoon } from 'react-icons/fa';
import { Link } from "react-router-dom";
import OrionIcon from '../../assets/images/orion.jpg';
import Orion from '../../assets/images/orion-img.jpg';
import OrionProduct from '../../assets/images/orion-product.jpg';
function Detail(props){
    const [sectiontype,setSectiontype] = useState('description');

    return (
        <>
                <h3 className="main-heading">PROJECTS</h3>
                <br/>
                <Row>
                    <div className="project-header">
                        <div className="img">
                            <img className="r-50" src={OrionIcon}/>
                        </div>
                        <div className="text-section">
                        <h3 className="text-black">Orion Money</h3>
                        <p>Cross-chain stablecoin bank</p>
                        </div>
                    </div>
                    <Col lg={8} sm={12} md={6} className="orderBy-2">
                        <img className="w-100 mt-3" src={Orion} />
                        <div className="nav-section">
                        <Nav variant="pills" defaultActiveKey="link-1">
                        <Nav.Item>
                            <Nav.Link eventKey="link-1" onClick={(e)=>setSectiontype('description')}>Description</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2" onClick={(e)=>setSectiontype('token-sale')}>Token Sale</Nav.Link>
                        </Nav.Item>
                        </Nav>
                        </div>
                        <div className="content-section">
                           {
                               sectiontype == 'description' ?
                               <>
                                <div className="text-box">
                            <h2>HIGHLIGHTS</h2>
                            <ul>
                            <li>Orion Money is a service developed to be a place where stablecoin holders can get a safe and secure yield of 20-25% APY in DeFi.</li>
                            <li>Orion Money brings high yields from other chains such as Terra to users on Ethereum through an easy decentralized application.</li>
                            <li>Orion Money managed to Raise $4.2 million Between seed and community rounds.</li>
                            </ul>
                            </div>
                            <div className="text-box">
                            <h2>PRODUCT</h2>
                            <h3>Orion Money is a product that provides fixed 13.5% APY on USDT, USDC, DAI, BUSD, as well as 16.5% APY on wUST.</h3>
                            <p>There are plans to increase this APY to 20-25% in the future after TGE. Additionally, users can make simple deposits, and Orion Money has passed three smart contract audits with amazing results while also managing to provide two insurance plans.</p>
                            <img className="custom-img" src={OrionProduct}/>
                            </div>
                            <div className="text-box">
                            <h2>PROBLEM</h2>
                            <h3>There is a $120 billion market cap of stablecoins, with the majority of them on Ethereum.</h3>
                            <p>Stablecoin yield is low and volatile. This being the case, stablecoin holders require a way through which they can get a high and fixed rate, where they can move their stablecoins to a blockchain with ease.</p>
                            </div>
                            <div className="text-box">
                            <h2>BUSINESS MODEL</h2>
                            <h3>The Orion Money Ecosystem generates revenue from the dApp itself and brings value to the native token, ORION.</h3>
                            <p>The majority of the dApp's initial revenue comes from the yield differential between what Orion Money receives from Anchor Protocol and what it has to pay to its users. The full net revenue will be used to buy ORION tokens on the open market, and this will be added into a Staking Fund. Furthermore, Orion Money has a validator on the Terra chain with $700m of Luna staked on it and will start charging a 5% commission at TGE. The commission will go to ORION token stakers.</p>
                            </div>
                            <div className="text-box">
                            <h2>MARKET</h2>
                            <h3>Our main competitors are Curve, Aave, and PancakeSwap.</h3>
                            <p>Compared to our competitors, we offer yield rates that are higher than all other DeFi and CeFi savings and lending dApps. Orion Saver is also a decentralized and smart contract-based dApp, where other savings protocols can be integrated into Orion Money as a yield resource.</p>
                            <img className="custom-img" src={OrionProduct}/>
                            </div>
                            <div className="text-box">
                            <h2>INVESTORS</h2>
                            <h3>We have raised $4.2 million from top VCs, cryptocurrency projects, and communities.</h3>
                            <p>This was raised from several top entities, including Delphi Digital, Hashed, Accomplice, SkyVision Capital, and the Orion Money Community farming.</p>
                            <img className="custom-img" src={OrionProduct}/>
                            </div>
                            <div className="text-box">
                            <h2>TEAM</h2>
                            <img className="custom-img" src={OrionProduct}/>
                            </div>
                            <div className="text-box">
                            <h2>TOKEN UTILITY</h2>
                            <h3>$ORION is the native cryptocurrency token used by Orion Money’s users and investors.</h3>
                            <p>This token can be staked through which the users can maximize their benefits. Stablecoin depositors get boosted yields depending on the number of ORION tokens they have staked. Investors or speculators are incentivized to stake ORION tokens to maximize the value of the tokens.</p>
                            <img className="custom-img" src={OrionProduct}/>
                            </div>
                               </>
                               :

                               <>
                               <Table striped bordered hover responsive >
                                <thead>
                                <tr>
                                <th class="text-start"> Project Key Metrics </th>
                                <th class="text-end"> </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                <td class="text-start"> <span class="text-color-03">Sale Start Time (UTC)</span> </td>
                                <td class="text-end" data-project-sale-metrics-target="saleStartTime">September 29th 2021, 1:00 PM UTC</td>
                                </tr>
                                <tr>
                                <td class="text-start"> <span class="text-color-03">Sale Start Time (UTC)</span> </td>
                                <td class="text-end" data-project-sale-metrics-target="saleStartTime">September 29th 2021, 1:00 PM UTC</td>
                                </tr>
                                <tr>
                                <td class="text-start"> <span class="text-color-03">Sale Start Time (UTC)</span> </td>
                                <td class="text-end" data-project-sale-metrics-target="saleStartTime">September 29th 2021, 1:00 PM UTC</td>
                                </tr>
                                <tr>
                                <td class="text-start"> <span class="text-color-03">Sale Start Time (UTC)</span> </td>
                                <td class="text-end" data-project-sale-metrics-target="saleStartTime">September 29th 2021, 1:00 PM UTC</td>
                                </tr>
                                </tbody>
                                </Table>
                               </>
                           }
                        </div>
                        
                    </Col>
                    <Col lg={4} sm={12} md={6} className="orderBy-1">
                        <div className="box form-small">
                           
                            <h4 className="text-black">Fundraise Goal</h4>
                            <div className="price-section">
                            <h2>$400,000</h2>
                            <i class="fab fa-ethereum"></i>
                            </div>
                            <div className="progress-section">
                            <ProgressBar now={60} label={`60%`} />

                            </div>
                            <div className="d-j-flex text-section">
                                <span>Allocation</span>
                                <span>TBA</span>
                            </div>
                            <div className="d-j-flex text-section">
                                <span>Price per token</span>
                                <span>TBA</span>
                            </div>
                            <div className="btn-group">
                            <div class="btn-custom btn-white-border">Sale</div>
                            </div>
                        </div>
                    </Col>
                </Row>
        
        </>
    )
}
export default Detail;