import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaUtensilSpoon } from 'react-icons/fa';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from "react-router-dom";
import CrowdSale from '../../assets/images/crowdsale.png';

function UpcomingSale(props){
    return (
        <>
                <h3 className="main-heading">UPCOMING SHARES</h3>
                <Row>
                    <Col lg={4} sm={12} md={6}>
                        <Link to={'/detail'}>
                        <div className="box">
                            <div className="b-btn-group">
                            <div>
                                <img width={100} src="https://storage.googleapis.com/polkastarter-production-assets/g1lh0pka1buydth3h0k38nk2t51b"/>
                            </div>
                            <div>
                            <div className="btn-custom btn-white-border">
                            <i class="fa fa-check"></i>
                            </div>
                            <div className="btn-custom btn-white-border">
                                Upcomming
                            </div>
                            </div>
                            </div>
                            <h4 className="title">DotOracle (DTO)</h4>
                            <h5 className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
                            <hr/>
                            <div className="calc-box">
                            <div className="d-j-flex">
                                    <div>
                                        <span>Personal Allocation</span>
                                        <h4>18.9999BNB</h4>
                                    </div>
                                    <div>
                                        <span>Total Raised</span>
                                        <h4>18.9999BNB</h4>
                                    </div>
                                </div>
                                <div className="auction">
                                    <h5>Auction Token Price</h5>
                                    <h5>18.9999BNB</h5>
                                <hr/>

                                </div>
                                <div className="auc-footer">
                                <h6>Auction Type</h6>
                                <div className="icon-section">
                                <img src={CrowdSale} />
                                <p>CrowdSale</p>
                                </div>
                                <hr/>
                                <div className="social-icon">
                                <a href=""><i class="fab fa-twitter"></i></a>
                                <a href=""><i class="fab fa-telegram"></i></a>
                                <a href=""><i class="fab fa-medium"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                   
                </Row>
        
        </>
    )
}
export default UpcomingSale;